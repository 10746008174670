import { notification } from "antd";

export const axiosCatch = (error) => {
  if (
    error?.response?.data?.message === "Unauthorized" ||
    error?.response?.data?.msg === "Unauthorized"
  ) {
    return;
  }
  notification.error({
    message:
      error?.response?.data?.message ||
      error?.response?.data?.msg ||
      error.message,
  });
};
