import axios from "axios";
import { API_BASE } from "./config";

const VINDO_SERVICE_BASE = API_BASE + "vindo/auth/";

const VINDO_UNAUTH_SERVICE_BASE = API_BASE + "/vindo/unauth/";

const createCompany = (data) => {
  return axios.post(VINDO_SERVICE_BASE + "create-company", data);
};

const checkDomian = (domain) => {
  return axios.get(VINDO_SERVICE_BASE + `check-domain/?domain=${domain}`);
};
const getLanguage = () => {
  return axios.get(VINDO_UNAUTH_SERVICE_BASE + `language-list`);
};
const getCurrency = () => {
  return axios.get(VINDO_UNAUTH_SERVICE_BASE + `currency-list`);
};

const AuthService = {
  createCompany,
  checkDomian,
  getLanguage,
  getCurrency,
};

export default AuthService;
