import { Button, Image, Typography } from "antd";

import successImage from "assets/images/paper airplane with check mark.png";
export default function SuccessPage({ setActiveTab }) {
  return (
    <div
      className="card-container center-items"
      style={{ flexDirection: "column" }}
    >
      <Typography.Text className="fw-500">SUCCESS</Typography.Text>
      <Image
        preview={false}
        src={successImage}
        style={{ marginBlock: "32px" }}
      />

      <Typography.Text className="fw-500">Invitation was sent</Typography.Text>
      <Typography.Text
        className="gc fz-12"
        style={{ marginBlock: "8px 24px", textAlign: "center" }}
      >
        Please check your email, your account will be activated within 24 hours
      </Typography.Text>

      <Button type="primary" className="w-100" onClick={() => setActiveTab(1)}>
        Got it
      </Button>
    </div>
  );
}
