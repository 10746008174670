import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { App as AppComponent } from "antd";

import App from "./App";

import "./styles/common.css";
import "./styles/root.css";
import "./styles/layout.css";
import "./styles/override.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#0318D6",
        colorPrimaryText: "#000",
        fontFamily: "'Poppins', sans-serif",
        borderRadius: 8,
        colorBorder: "#D1D1D6",
        controlOutlineWidth: 1,
        controlHeight: 44,
        colorBgLayout: "#fff",
        controlOutline: "transparent",
      },
      components: {
        Typography: {
          sizeMarginHeadingVerticalStart: 0,
          sizeMarginHeadingVerticalEnd: 0,
        },
        Checkbox: {
          borderRadiusSM: 4,
          colorBgContainer: "transparent",
          colorPrimaryBorderHover: "#eee",
        },
        Menu: {
          colorActiveBarBorderSize: 0,
          colorItemText: "#8E8E93",
          colorItemTextSelected: "#272942",
          colorItemBgSelected: "#fff",
          borderRadius: 14,
        },
        Select: {
          fontSize: 12,
        },
        Table: {
          colorBgContainer: "transparent",
        },
        Input: {
          colorBgBase: "#fff",
          fontSize: 12,
        },
        Tooltip: {
          controlHeight: 36,
        },
      },
    }}
  >
    <AppComponent>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppComponent>
  </ConfigProvider>,
);
