import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";

import AuthView from "views/Auth";

export default function VindoRouter() {
  return (
    <Layout>
      <Routes>
        <Route index element={<AuthView />} />
      </Routes>
    </Layout>
  );
}
