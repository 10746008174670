import { useState } from "react";
import { Col, Image, Row } from "antd";

import Login from "./Login";
import SuccessPage from "./SuccessPage";

import vindoLogo from "assets/svgs/LogoSVG.svg";
import signupBG from "assets/images/signup.png";

import "./style.css";

export default function LoginView() {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <Row className="auth-view">
      <Col
        xs={0}
        lg={14}
        className="auth-view-image"
        style={{
          background: `url(${signupBG})`,
        }}
      ></Col>
      <Col
        xs={24}
        lg={10}
        className="center-items"
        style={{ flexDirection: "column" }}
      >
        <div>
          <Image preview={false} height={55} src={vindoLogo} />
        </div>

        <div className="login-card">
          {activeTab === 1 ? (
            <Login setActiveTab={setActiveTab} />
          ) : (
            <SuccessPage setActiveTab={setActiveTab} />
          )}
        </div>
      </Col>
    </Row>
  );
}
