import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";

import AuthService from "services/auth.service";

import {
  CheckOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { axiosCatch } from "utils/axiosUtils";

const { Option } = Select;

export default function Signup({ setActiveTab }) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [vaildDomainName, setVaildDomainName] = useState(false);
  const [language, setLanguage] = useState([]);
  const [currency, setCurrency] = useState([]);
  const domain = Form.useWatch("domain", form);

  useEffect(() => {
    form.getFieldInstance("domain").focus();
    if (domain && domain.length > 0) {
      if (!/^(?!-)(?!.*-$)(?!.*--)[A-Za-z0-9-]{1,16}$/.test(domain)) {
        setVaildDomainName(false);
        return;
      }
      const delayDebounceFn = setTimeout(
        () => {
          (async () => {
            try {
              setLoading(true);
              await AuthService.checkDomian(domain);
              setVaildDomainName(true);
            } catch (err) {
              setVaildDomainName(false);
            } finally {
              setLoading(false);
            }
          })();
        },
        domain.length > 0 ? 500 : 0,
      );

      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  useEffect(() => {
    const getCurrencyAndLanguage = async () => {
      setAddLoading(true);
      try {
        const getLangData = await AuthService.getLanguage();
        setLanguage(getLangData.data.data);
        const getCurrencyData = await AuthService.getCurrency();
        setCurrency(
          getCurrencyData.data.data.sort((a, b) => {
            if (a.code < b.code) {
              return -1;
            }
            if (a.code > b.code) {
              return 1;
            }
            return 0;
          }),
        );
      } catch (err) {
        axiosCatch(err);
      } finally {
        setAddLoading(false);
      }
    };
    getCurrencyAndLanguage();
  }, []);

  const Signup = async (values) => {
    if (!vaildDomainName) {
      notification.error({ message: "You Should Enter Vaild Domain Name" });
      return;
    }
    try {
      setAddLoading(true);
      await AuthService.createCompany({
        name: values.name,
        domain,
        email: values.email,
        fullName: values.fullName,
        languageCode: values.language,
        currencyCode: values.currency,
      });
      notification.success({
        message: `${values.name} Company Has Been Created Successfully`,
      });
      setActiveTab(2);
    } catch (err) {
      axiosCatch(err);
    } finally {
      setAddLoading(false);
    }
  };

  return (
    <div className="card-container">
      <Row justify="center" style={{ marginBottom: "40px" }}>
        <Typography.Text className="fz-14">
          CREATE YOUR VINDO ACCOUNT
        </Typography.Text>
      </Row>
      <Form
        form={form}
        layout="vertical"
        onFinish={Signup}
        requiredMark={false}
      >
        <Row gutter={[0, 8]}>
          <Col xs={24}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Enter Company Name",
                },
              ]}
              label="Company Name"
              className="w-100"
            >
              <Input
                placeholder="Enter your Company Name"
                className="login-inputs"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please Enter Email",
                },
              ]}
              label="Email"
              className="w-100"
            >
              <Input
                type="email"
                placeholder="Enter your Email"
                className="login-inputs"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="fullName"
              rules={[
                {
                  required: true,
                  message: "Please Enter Full Name",
                },
              ]}
              label="Full Name"
              className="w-100"
            >
              <Input
                placeholder="Enter your Full Name"
                className="login-inputs"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="domain"
              rules={[
                {
                  required: true,
                  message: "Please Enter Domain Name",
                },
                {
                  validator(_, value, callback) {
                    if (!value) {
                      return Promise.reject();
                    }
                    if (value.length > 16) {
                      return Promise.reject(
                        "Domain length should be less than 17",
                      );
                    }

                    if (
                      value &&
                      !/^(?!-)(?!.*-$)(?!.*--)[A-Za-z0-9-]{1,16}$/.test(value)
                    ) {
                      return Promise.reject("Please Enter Vaild Domain Name");
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              label="Domain"
              className="w-100"
            >
              <Input
                placeholder="Enter your Domain"
                className="login-inputs"
                suffix={
                  loading ? (
                    <LoadingOutlined />
                  ) : domain && domain.length > 0 ? (
                    vaildDomainName ? (
                      <CheckOutlined />
                    ) : (
                      <Dropdown
                        placement="top"
                        arrow
                        dropdownRender={() => (
                          <div className="domain-example">
                            <Row>
                              <Col xs={24}>
                                <Typography.Text className="fz-12">
                                  - Ensures that the string does not start or
                                  end with a hyphen.
                                </Typography.Text>
                              </Col>
                              <Col xs={24}>
                                <Typography.Text className="fz-12">
                                  - Ensures that the string does not contain two
                                  consecutive hyphens.
                                </Typography.Text>
                              </Col>
                              <Col xs={24}>
                                <Typography.Text className="fz-12">
                                  - Ensures that the string contains onlay
                                  English letter, digit, or hyphen.
                                </Typography.Text>
                              </Col>
                            </Row>
                          </div>
                        )}
                      >
                        <ExclamationCircleOutlined style={{ color: "#f00" }} />
                      </Dropdown>
                    )
                  ) : null
                }
              />
            </Form.Item>

            {domain && domain.length > 0 && (
              <div
                className="domain-name-check"
                style={{ transform: !vaildDomainName && "translateY(16px)" }}
              >
                <Typography.Text className="gc fz-12">
                  {`https://${domain}.vindo.ai`}
                </Typography.Text>
              </div>
            )}
          </Col>
          <Col xs={24}>
            <Form.Item
              name="language"
              rules={[
                {
                  required: true,
                  message: "Please Enter language",
                },
              ]}
              label="Language"
              className="w-100"
            >
              <Select
                allowClear
                showSearch
                filterOption={(inputValue, option) =>
                  option.children
                    .join("")
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                placeholder="Select the language"
              >
                {language.map((lang) => (
                  <Option key={lang.code} value={lang.code}>
                    {lang.nativeName}{" "}
                    <Avatar
                      shape="square"
                      size={25}
                      src={lang.flag}
                      alt="lang.nativeName"
                    />
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="currency"
              rules={[
                {
                  required: true,
                  message: "Please Enter currency",
                },
              ]}
              label="Currency"
              className="w-100"
            >
              <Select
                allowClear
                showSearch
                filterOption={(inputValue, option) =>
                  option.children
                    .join("")
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                placeholder="Select the currency"
              >
                {currency.map((currencyItem) => (
                  <Option key={currencyItem.code} value={currencyItem.code}>
                    {currencyItem.code} [{currencyItem.symbol}]
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: "24px" }}>
          <Form.Item className="w-100">
            <Button
              loading={addLoading}
              type="primary"
              className="sign-in-btn w-100"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Row>

        <Row style={{ marginTop: "40px" }}>
          <Typography.Text className="fz-12">
            Protected by reCAPTCHA and subject to the{" "}
            <span style={{ color: "#3A5EE3" }}>Vindo Privacy Policy</span> and{" "}
            <span style={{ color: "#3A5EE3" }}>Terms of Service.</span>
          </Typography.Text>
        </Row>
      </Form>
    </div>
  );
}
